<template>
  <v-app>
    <body>
      <v-container>
        <v-container class="white-bg mt-48px">
          <v-row justify="center">
            <h4 class="navy-text">PAYMENT</h4>
          </v-row>
          <v-row justify="center">
            <div class="subtitle">การชำระเงิน</div>
          </v-row>
          <v-divider></v-divider>
          <v-content class="pa-0">
            <div class="ct-mw">
              <!-- Section 1 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0">
                  <v-row justify="start" class="mt-2" tag="caption">
                    <p><strong>เลือกวิธีการชำระเงิน</strong></p>
                  </v-row>
                </v-col>
                <v-col cols="6" class="ma-0">
                  <v-row justify="end" class="mt-2" tag="caption">
                    <p>Order ID : {{ odId }} </p>
                  </v-row>
                </v-col>
              </v-row>
              <div class="section">
                <v-row justify="center" tag="caption">
                   <p>จำนวนเงินที่ต้องชำระ {{ showNet() }} บาท</p>
                </v-row>
                <v-divider class="mw-divider"></v-divider><br/>
                <v-row v-if="net >= 3500">
                  <v-col cols="12" md="4">
                    <div @click="checkout('credit_card')"
                    class="mw-method payment-channel1"></div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div @click="checkout('internet_banking')"
                    class="mw-method payment-channel2"></div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div @click="checkout('installment')"
                    class="mw-method payment-channel3"></div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" md="6">
                    <div @click="checkout('credit_card')"
                    class="mw-method payment-channel1"></div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div @click="checkout('internet_banking')"
                    class="mw-method payment-channel2"></div>
                  </v-col>
                </v-row>
              </div><br/>
               <v-row justify="center">
                <router-link
                  :to="{ name: 'review', params: { sku: this.$route.params.sku } }">
                  <v-btn class="mb-4">BACK</v-btn>
                </router-link>
               </v-row>
            </div>
          </v-content>
        </v-container>
      </v-container>
    </body>
  </v-app>
</template>

<script>
import numeral from 'numeral';
import payment from '../../library/payment';
import fbFunctions from '../../firebase/function';

const { errorMsg } = require('../../static/errorMessage');

export default {
  props: ['orderCheckout'],
  data() {
    return {
      net: '',
      od: '',
      odId: '',
    };
  },
  mounted() {
    this.$store.dispatch('setLoading', true);
    const { sku, orderCheckout } = this.$route.params;
    console.log(orderCheckout);
    if (!sku) {
      this.$router.go(-1);
    }
    payment.createOrder(orderCheckout)
      .then((did) => {
        this.odId = did;
        this.od = orderCheckout;
        this.net = orderCheckout.price - orderCheckout.discount;
        // eslint-disable-next-line no-undef
        OmiseCard.configure({
          publicKey: process.env.VUE_APP_OMISEPUBKEY,
          image: `${process.env.VUE_APP_BASE_URL}/img/icons/achieve-plus-favicon.svg`,
          currency: 'THB',
        });
        // eslint-disable-next-line no-undef
        OmiseCard.attach();
        this.$store.dispatch('setLoading', false);
      });
  },
  methods: {
    showNet() {
      return numeral(this.net).format('0,0');
    },
    checkout(method) {
      const { uid } = this.$store.getters.user;
      const payAmount = this.net * 100;
      // eslint-disable-next-line no-undef
      OmiseCard.open({
        defaultPaymentMethod: method,
        frameLabel: 'Achieve Plus',
        submitLabel: 'PAY ',
        amount: payAmount,
        onCreateTokenSuccess: async (token) => {
          this.$store.dispatch('setLoading', true);
          if (this.od.promotion !== '') {
            await payment.updatePromoHist(this.od.promotion.trim().toUpperCase(), uid);
          }
          const order = this.od;
          order.id = this.odId;
          fbFunctions.chargeOmise({
            uid, token, method, order,
          })
            .then((result) => {
              this.$store.dispatch('setLoading', false);
              window.location.replace(result.data.authorize_uri);
            })
            .catch(() => {
              this.$store.dispatch('setError', errorMsg['other/fallback']);
            });
        },
      });
    },
  },
};
</script>


<style lang="sass" scoped>
.ct-mw
  max-width: 960px
  margin: auto
.white-bg
  max-width: 1100px
  margin: auto
.section
  padding: 16px
  border-color: #DBDBDB
  border-radius: 5px
  border-style: solid
  margin: auto

.detail-text
  font-weight: 200

.bg-grey
  background-color: #DBDBDB
  padding-top: 4px
  padding-bottom: 4px

.mw-divider
  max-width: 300px
  margin-left: auto
  margin-right: auto
  margin-top: -8px

.payment-channel1
  padding: auto
  margin: auto
  width: 200px
  height: 200px
  background-size: 200px 200px
  background-image: url('../../assets/image/credit-card.png')
  background-position: center
  background-repeat: no-repeat
  cursor: pointer

.payment-channel1:hover
  background-image: url('../../assets/image/credit-card-hover.png')
  cursor: pointer

.payment-channel2
  padding: auto
  margin: auto
  width: 200px
  height: 200px
  background-size: 200px 200px
  background-image: url('../../assets/image/internet-banking.png')
  background-repeat: no-repeat
  background-position: center
  cursor: pointer

.payment-channel2:hover
  background-image: url('../../assets/image/internet-banking-hover.png')
  cursor: pointer

.payment-channel3
  padding: auto
  margin: auto
  width: 200px
  height: 200px
  background-size: 200px 200px
  background-image: url('../../assets/image/installment.png')
  background-repeat: no-repeat
  background-position: center
  cursor: pointer

.payment-channel3:hover
  background-image: url('../../assets/image/installment-hover.png')
  cursor: pointer
.mw-method
  max-width: 200px
  margin-top: 16px
  margin-bottom: 16px
</style>
