import axios from 'axios';
import qs from 'qs';

const FUNCTIONS_API = process.env.VUE_APP_FUNCTIONS_API;
const CUMA_FUNCTIONS_API = process.env.VUE_APP_CUMA_API;

const createTokenFromOauthToken = async ({ token, uid }) => {
  const option = {
    method: 'POST',
    url: `${FUNCTIONS_API}/saveCumaToken`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      token,
      uid,
    }),
  };
  try {
    return await axios(option);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const chargeOmise = ({
  uid, token, method, order,
}) => {
  const option = {
    method: 'POST',
    url: `${FUNCTIONS_API}/chargeOmise`,
    data: qs.stringify({
      uid,
      token,
      method,
      order,
    }),
  };
  return axios(option)
    .catch(error => error);
};

const getCumaPurchaseRecords = async ({ date, pointMaxAge, token }) => {
  const option = {
    method: 'GET',
    url: `${CUMA_FUNCTIONS_API}/purchase-record/${date}/${pointMaxAge}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios(option);
    return res.status ? res.data : null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default {
  createTokenFromOauthToken,
  chargeOmise,
  getCumaPurchaseRecords,
};
